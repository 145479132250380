<template>
  <div class="expiration">
    <div class="enable-box">
      <el-switch
        v-model="overInfo.Useable"
        :active-value="true"
        :inactive-value="false"
      >
      </el-switch>
      <i class="el-icon-warning-outline"></i>
      <!-- <span
        >具有流失风险的顾客，可向其发送红包或推广商品，吸引其进行消费，唤回顾客</span
      > -->
      <span
        >具有流失风险的顾客，可向其发送提醒通知，吸引其进行消费，唤回顾客</span
      >
    </div>

    <div class="">
      <el-form ref="form" label-width="80px">
        <!-- 未开启发送 -->
        <template v-if="!overInfo.Useable">
          <el-form-item class="df-col">
            <div>功能描述：开启后，客户权益到期前生成回访任务</div>
            <el-image :src="imgUrl" fit="contain"></el-image>
          </el-form-item>
        </template>

        <!-- 开启发送 -->
        <template v-else>
          <!-- 周期流失 -->
          <el-form-item label="流失条件">
            <div class="condition">
              权益即将到期前
              <el-input
                v-model="overInfo.ExpirePreDays"
                size="small"
                clearable
                @change=""
              ></el-input
              >天
            </div>
          </el-form-item>

          <!-- 营销模式 -->
          <el-form-item label="营销模式">
            <el-radio-group v-model="radio" @change="onChangeMarket">
              <el-radio :label="0">
                <span>自动发送</span>

                <el-popover placement="top" trigger="hover">
                  <div class="content">
                    <div>在下方配置赠送内容和短信通知，</div>
                    <div>由系统自动向满足流失风险条件的顾客进行发送</div>
                  </div>
                  <i class="el-icon-warning-outline" slot="reference"></i>
                </el-popover>
              </el-radio>

              <el-radio :label="1">
                <span>员工发送</span>

                <el-popover placement="top" trigger="hover">
                  <div class="content">
                    <div>在下方配置营销方案，</div>
                    <div>
                      顾客具有流失风险时自动生成管理员或员工的待办事项，
                    </div>
                    <div>
                      提醒TA在联系顾客，对顾客进行挽回营销
                    </div>
                  </div>
                  <i class="el-icon-warning-outline" slot="reference"></i>
                </el-popover>
              </el-radio>
            </el-radio-group>
          </el-form-item>

          <template v-if="radio == 0">
            <!-- 发送时间 -->
            <el-form-item label="发送时间">
              <div class="select-time-box">
                <el-time-picker
                  v-model="sendDate"
                  size="small"
                  :picker-options="{
                    selectableRange: '00:00:00 - 23:59:59',
                    format: 'HH:mm',
                  }"
                  placeholder="选择时间点"
                  @change="onChangeDate"
                >
                </el-time-picker>
              </div>
            </el-form-item>

            <!-- 发送类型 -->
            <el-form-item label="发送类型">
              <div class="template-box">
                <el-radio-group
                  v-model="remindMember.WxOrSms"
                  @change="onChangeRadio"
                >
                  <el-radio :label="1">微信</el-radio>
                  <el-radio :label="2">短信</el-radio>
                </el-radio-group>
              </div>
            </el-form-item>

            <!-- 发送内容 -->
            <el-form-item label="发送内容">
              <el-button size="small" type="primary" @click="onShowTemplate"
                >选择模板</el-button
              >

              <div class="notice-box">
                <el-input
                  id="Textarea"
                  ref="Textarea"
                  v-model="notice"
                  placeholder="您可以选择短信模板或在此输入短信通知内容"
                  type="textarea"
                  :rows="5"
                  maxlength="192"
                  show-word-limit
                  @blur="onSaveContent"
                ></el-input>

                <div class="text-insert">
                  <span class="title"
                    >内容中包含以下内容可点击蓝色文字快速插入，发送时会以实际内容代替</span
                  >
                  <div v-for="(item, index) in templateSymbolList" :key="index">
                    <span @click="onInsetSymbol(item)">{{ item.Value }}</span>
                    {{ item.Desc }}
                  </div>
                </div>
              </div>

              <!-- 保存模板 -->
              <el-checkbox v-model="saveAs" @change="onChangeSaveAs"
                >保存为【权益到期】类型的
                <span :class="remindMember.WxOrSms == 1 ? 'wx' : 'msg'">{{
                  remindMember.WxOrSms == 1 ? "微信" : "短信"
                }}</span>
                模板</el-checkbox
              >
            </el-form-item>
          </template>

          <!-- 提醒谁 -->
          <Remind
            v-else-if="radio > 0 || remindEmployee.Useable"
            ref="Remind"
            :dataInfo="{ employee: remindEmployee, manager: remindManager }"
            @change="onChangeRemind"
          ></Remind>

          <!-- 保存按钮 -->
          <el-form-item label="">
            <el-button type="primary" size="small" @click="saveFeature"
              >保存</el-button
            >
          </el-form-item>
        </template>
      </el-form>
    </div>

    <DfSmsTemplate
      ref="DfSmsTemplate"
      @confirm="onChangeTemplate"
    ></DfSmsTemplate>
  </div>
</template>

<script>
import Remind from "../components/remind.vue";

import Marketing from "@/api/marketing.js";
import Template from "@/api/template.js";

let img = require("@/assets/img/no-pictrue-rectangle.png");
export default {
  components: { Remind },

  data() {
    return {
      code: "EquityExpire", // 分类code
      sendDate: "", // 发送时间
      notice: "", // 短信文本
      cursorPosition: 0, // 光标位置
      imgUrl: img, // 默认图片
      radio: -1, // 单选框选中值
      percentage: "", // 权益到期百分比
      term: "", // 期限
      message: "", // 通知内容
      overInfo: {}, // 流失信息
      remindEmployee: {}, // 门店员工信息
      remindManager: {}, // 门店管理员信息
      remindMember: {
        Useable: false,
      }, // 提醒顾客信息
      selectTemItem: {}, // 选中的短信模板信息
      templateSymbolList: [], // 模板符号列表
      saveAs: false, // 保存为短信模板选项框
    };
  },

  async mounted() {
    let date = new Date(),
      year = date.getFullYear(),
      month = date.getMonth() + 1,
      day = date.getDate();

    // this.saveAs = window.localStorage.getItem(`${this.code}TempSaveAs`)
    await this.getFeatureData();
    await this.getTemplateSymbols();
    this.sendDate = await new Date(
      year,
      month,
      day,
      this.remindMember.SendHour || 0,
      this.remindMember.SendMinute || 0,
      0
    );
  },

  methods: {
    // 获取功能数据
    async getFeatureData() {
      try {
        let { data } = await Marketing.getFeatureData({
          code: this.code,
        });
        let { RemindEmployee, RemindManager, RemindMember } = data;

        this.remindEmployee = RemindEmployee;
        this.remindManager = RemindManager;
        this.remindMember = RemindMember;
        this.overInfo = {
          ExpirePreDays: data.ExpirePreDays,
          Useable: data.Useable,
        };
        if (this.remindManager.Useable || this.remindMember.Useable) {
          this.radio = this.remindMember.Useable ? 0 : 1;
        }else if(this.remindEmployee.Useable){
          this.radio = 1;
        }
        this.onChangeRadio();
      } catch (err) {
        console.log(err);
      }
    },

    // 获取模板符号
    async getTemplateSymbols() {
      try {
        let { data } = await Template.getTemplateSymbols({
          class_code: this.code,
        });
        this.templateSymbolList = data;
      } catch (err) {
        console.log(err);
      }
    },

    // 添加消息模板
    async addTemplate() {
      let { remindMember, code, notice } = this;
      try {
        let submitData = {
          ClassCode: code,
          ApplyType: remindMember.WxOrSms,
          TplContent: notice,
        };
        let { errcode } = await Template.addTemplate(submitData);
        if (errcode == 0) {
          this.$message.success("添加成功!");
          this.getFeatureData();
        }
      } catch (err) {
        console.log(err);
      }
    },

    // 保存功能数据
    async saveFeature() {
      let {
        remindEmployee,
        remindManager,
        remindMember,
        overInfo,
        code,
        radio,
      } = this;

      if (radio < 0) {
        return this.$message.error("请选择营销模式");
      }

      try {
        let submitData = {
          Code: code,
          Data: {
            ...overInfo,
            RemindEmployee: remindEmployee,
            RemindManager: remindManager,
            RemindMember: remindMember,
            ExpirePreDays: Number(overInfo.ExpirePreDays),
          },
        };
        console.log(submitData);
        let { errcode } = await Marketing.saveFeature(submitData);
        if (errcode == 0) {
          this.$message.success("设置保存成功!");
          if (!this.saveAs) {
            return this.getFeatureData();
          }
          setTimeout(() => {
            this.addTemplate();
          }, 1000);
        }
      } catch (err) {
        console.log(err);
      }
    },

    // 符号插入事件
    onInsetSymbol(event) {
      let dom = document.getElementById("Textarea");
      // 检查浏览器是否支持 selectionRange 属性
      if (typeof dom.selectionStart === "number") {
        this.cursorPosition = dom.selectionStart;
      } else if (document.selection) {
        // 这段代码适用于旧版本的 Internet Explorer
        dom.focus();
        let selection = document.selection.createRange();
        let selectionLength = document.selection.createRange().text.length;
        selection.moveStart("character", -dom.value.length);
        this.cursorPosition = selection.text.length - selectionLength;
      } else {
        this.cursorPosition = 0;
      }

      this.$nextTick(() => {
        let { notice } = this;
        this.notice =
          notice.slice(0, this.cursorPosition) +
          event.Value +
          notice.slice(this.cursorPosition);
        this.onSaveContent();
      });
    },

    // 打开模板选择对话框
    onShowTemplate() {
      this.$refs.DfSmsTemplate.onShowDialog({
        applyType: this.remindMember.WxOrSms,
        classCode: this.code,
      });
    },

    // 保存为模板选择框改变事件
    onChangeSaveAs(event) {
      window.localStorage.setItem(`${this.code}TempSaveAs`, event);
    },

    // 营销模式改变事件
    onChangeMarket(event) {
      // console.log(event);
      if (event) {
        this.remindManager.ManagerGuid = "";
        this.remindMember.Useable = false
      }else{
        this.remindMember.Useable = true
        this.remindManager.ManagerGuid = "";
        this.remindManager.Useable = false;
      }
    },

    // 发送类型改变事件
    onChangeRadio() {
      this.notice =
        this.remindMember.WxOrSms == 1
          ? this.remindMember.WxTemplate
          : this.remindMember.SmsTemplate;
    },

    // 发送时间改变事件
    onChangeDate(event) {
      let hour = event.getHours(),
        minute = event.getMinutes();
      this.remindMember.SendHour = hour;
      this.remindMember.SendMinute = minute;
    },

    // 保存发送内容
    onSaveContent() {
      if (this.remindMember.WxOrSms == 1) {
        this.remindMember.WxTemplate = this.notice;
      } else {
        this.remindMember.SmsTemplate = this.notice;
      }
    },

    // 模板选中事件
    onChangeTemplate(event) {
      this.selectTemItem = event;
      this.notice = event.TplContent;
      this.onSaveContent();
    },

    // 提醒员工选中
    onChangeRemind(event) {
      this.remindEmployee = event.employee;
      this.remindManager = event.manager;
      // console.log(this.remindEmployee, this.remindManager);
    },
  },
};
</script>

<style lang="less" scoped>
.expiration {
  .enable-box {
    margin: 20px 0;

    .market-i(20px);
    i:hover {
      color: #ccc !important;
      cursor: unset !important;
    }

    span {
      margin-left: 4px;
      font-size: 12px;
      color: #999;
    }
  }

  .el-form {
    .el-form-item {
      // display: table;
      .el-image {
        height: 300px;
      }

      .condition {
        .flex-row;

        .el-input,
        .warn {
          margin: 0 5px;
        }

        .el-input {
          width: 80px;
        }

        .warn {
          color: orange;
        }

        .strong {
          font-weight: bold;
        }
      }

      .auto-send {
        .flex-row;
        justify-content: unset;
        height: 100%;
        padding: 20px;
        box-sizing: border-box;
        line-height: 0;
        box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.1);

        > div {
          height: 100%;
        }

        .content {
          .flex-row;
          flex: 1;

          .edit,
          .edit i {
            margin-left: 10px;
            color: #409eff !important;
            cursor: pointer;
          }
        }
      }

      .notice-box {
        .flex-row();
        align-items: unset;
        width: 60vw;

        .el-textarea {
          width: 50%;
        }

        .text-insert {
          margin-left: 20px;
          font-size: 12px;
          line-height: 20px;
          color: #999;

          .title {
            color: black;
          }

          div > span {
            color: #409eff;
            cursor: pointer;
          }
        }
      }
    }
  }

  .market-i();
}
</style>
